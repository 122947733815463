.logo {
    img {
        width: 170px;
        @media screen and (max-width: 900px) and (min-width: 320px) {
            width: 140px;
        }
    }
    img.lightmode {
        display: block;
        @media (prefers-color-scheme: dark) {
            display: none;
        }
    }
    img.darkmode {
        display: none;
        opacity: 0.75;
        @media (prefers-color-scheme: dark) {
            display: block;
        }
    }
}
