.feature-text-filled {
    display: flex;
    padding: 0 40px;
    align-items: center;
    justify-content: flex-start;
    // height: 170px;
    // border: 4px #88C4F0 solid;
    background-color: #CFE5FF;
    border-radius: 40px;

    @media screen and (max-width: 900px) and (min-width: 320px) {
        padding: 24px 40px;
        margin-bottom: 12px;
    }

    &-label {
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        @media screen and (max-width: 900px) and (min-width: 320px) {
            font-size: 21px;
            line-height: 27px;
        }
    }

    @media (prefers-color-scheme: dark) {
        background-color: #3c5a7d;
        background-color: #3c5a7d22;
        color: #6a99cf;
    }
}
