.app-store-btn {
    padding: 28px 0;
    a {display: inline-block; overflow: hidden;}
    img {width: 180px;}
    @media screen and (max-width: 900px) and (min-width: 320px) {
        text-align: center;
        img {width: 140px;}
    }
    @media (prefers-color-scheme: dark) {
        opacity: .7;
    }
}

