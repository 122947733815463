

.App {
    margin: 0 auto;
    padding: 0 24px;
    max-width: 1200px;
    height: 100vh;
    color: #2E2E43;
    white-space: pre-line;
    @media screen and (max-width: 900px) and (min-width: 320px) {
        max-width: 380px;
    }
    @media (prefers-color-scheme: dark) {
        color: #FFFFFFCC;
    }
}

a {
    text-decoration: none;
}

img {
    vertical-align: top;
}
