.main {
    position: relative;

    @media screen and (max-width: 900px) and (min-width: 320px) {
        margin: 0 -24px;
        padding: 0 24px;
        overflow: hidden;
    }

    &-left {
        width: 45%;
        padding-top: 48px;
        @media screen and (max-width: 900px) and (min-width: 320px) {
            padding-top: 0px;
            width: auto;
        }
    }

    &-title {
        padding: 12px 0;
        margin-bottom: 24px;
        font-family: 'Jost', sans-serif;
        max-width: 900px;
        font-weight: 700;
        font-size: 66px;
        line-height: 66px;
        letter-spacing: -1px;
        background-image: linear-gradient(360deg, #6c44ff 23.95%, rgb(65, 80, 255) 47.48%, #3b8eff 69.75%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        @media screen and (max-width: 900px) and (min-width: 320px) {
            max-width: none;
            font-size: 32px;
            line-height: 36px;
            margin-bottom: 12px;
            letter-spacing: 0;
        }
        @media (prefers-color-scheme: dark) {
            background-image: linear-gradient(358deg, #cf52ff 15.97%, rgb(67, 129, 255) 49.58%, #00beff 85.29%);
            background-clip: text;
        }
    }

    &-text {
        width: 380px;
        margin-top: 24px;
        font-size: 22px;
        line-height: 32px;
        strong {
            font-weight: 700;
        }
        @media screen and (max-width: 900px) and (min-width: 320px) {
            width: auto;
            font-size: 21px;
            line-height: 25px;
            margin-bottom: 12px;
        }
    }

    &-phones {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 40%;
        height: 130%;
        right: 12%;
        top: -15%;
        pointer-events: none;

        img {
            width: 100%;
        }

        @media screen and (max-width: 900px) and (min-width: 320px) {
            position: static;
            width: auto;
            height: auto;
            margin: 0 auto;
            right: auto;
            top: auto;
            img {
                width: 90%;
            }
        }

    }

    &-palki {
        position: absolute;
        right: 0;
        bottom: 0;
        img {
            position: absolute;
            width: 300px;
        }
        .palka-1 {
            transform: rotate(75deg);
            bottom: 473px;   
            right: -7px;
        }
        .palka-2 {
            transform: rotate(45deg);
            bottom: 280px;   
            right: -30px;
        }
        .palka-3 {
            transform: rotate(110deg);
            bottom: 188px;   
            right: -80px;
        }
        .palka-4 {
            transform: rotate(-20deg);
            bottom: 0px;   
            right: -10px;
        }
        @media screen and (max-width: 900px) and (min-width: 320px) {
            position: relative;
            width: 320px;
            height: 200px;
            margin: 80px auto;
            img {
                width: 340px;
                margin-left: -170px;
            }
            .palka-1 {
                transform: rotate(5deg);
                top: 0;
                bottom: auto;
                right: auto;
                left: 50% + 14;
                z-index: 4;
            }
            .palka-2 {
                transform: rotate(15deg);
                bottom: auto;
                top: 60px;
                right: auto;
                left: 50% - 15;
                z-index: 2;
            }
            .palka-3 {
                transform: rotate(-12deg);
                top: 100px;
                bottom: auto;
                right: auto;
                left: 50% + 7;
                z-index: 4;
            }
            .palka-4 {
                transform: rotate(4deg);
                top: 180px;
                bottom: auto;
                right: auto;
                left: 50% - 5;
            }
        }
    }

}
